import * as React from "react";
import { graphql, Link } from "gatsby";
import "../../styles/main.scss";

export default function BlogIndexPage ({ data }) {
  const { edges: posts } = data.allMarkdownRemark;

  return(
    <main>
      <Link to="../">ᐊ zurück</Link>
      <h1>Blog</h1>
      <h2>Neuste Einträge</h2>
      <ul>
        {posts.map(({node: post}) => (
          <li key={post.id}>
            <Link to={"/blog/" + post.parent.name}>
              {post.frontmatter.title}
            </Link>
            <p>{post.excerpt}</p>
          </li>
        ))}
      </ul>
    </main>
  );
};

export const pageQuery = graphql`
  query BlogIndexPage {
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY")
          }
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`
